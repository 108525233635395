<template>
  <section
    class="py-2 noselect d-flex align-items-center flex-column"
    id="aboutme"
  >
    <h2 class="text-center fw-bold">About me</h2>

    <div class="container my-auto">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="d-flex flex-column align-items-center">
            <img src="assets/avatar.png" class="avatar" alt="" />
            <p class="mt-2 p-2 fs-5">
              Hi, I am Steven. I currently study Mechatronics system engineering
              in Germany, and work as IT expert and Web developer at
              <a
                href="https://www.perspectives.cc "
                target="_blank"
                class="link-light"
                >Perspectives Climate Group</a
              >
               You can find me on:
            </p>
            <div class="d-flex flex-row">
              <AboutButton icon="fas fa-file-contract" btn="resume" link="https://resume.io/r/YkojQHscN" title="My resume"> </AboutButton>
              <AboutButton icon="fab fa-linkedin" btn="linkedin" link="https://www.linkedin.com/in/steven-fokoua" title="Linkedin"> </AboutButton>
              <AboutButton icon="fab fa-github" btn="git" link="https://github.com/stevenfokoua" title="Github "> </AboutButton>
            </div>
            <ul class="list-unstyled list-inline">
              <li class="list-inline-item p-2">HTML/CSS/JS</li>
              <li class="list-inline-item p-2">Vue3.js</li>
              <li class="list-inline-item p-2">Bootstrap 5</li>
              <li class="list-inline-item p-2">Git</li>
              <li class="list-inline-item p-2">Wordpress</li>
              <li class="list-inline-item p-2">Figma</li>
              <li class="list-inline-item p-2">
                Adobe Photoshop & Illustrator
              </li>
            </ul>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div class="d-flex flex-wrap">
            <div class="col-sm-6">
              <h5 class="">How I got into web development</h5>
              <p class="mt-2 text-start p-2">
                I grew up in my home country, Cameroon, and always saw
                technology as "the future". I've always had a burning passion
                for it and from the age of 11 was determined to learn as much as
                possible. My aim was, like many kids, to make video games. But I
                needed to promote my game and present it to the world. Despite
                the very poor internet we had, I went on and spent nights upon
                nights scouring the internet and teaching myself a variety of
                skills, from basic computer terminology to complex programming
                concepts. After many years of refining my art, digging and
                building various amateur projects for myself and various people
                around me, I finally jumped into actual freelancing with the aim
                to make the web better for all. Despite Studying a mostly
                unrelated course, my heart always and will always belong to this
                creative art for which I have so much passion.
              </p>
            </div>
            <div class="col col-sm-6">
              <h5 class="">About this site</h5>
              <p class="mt-2 text-start p-2">
                This site is built with Bootstrap 5, Vue 3 and lots of love. It
                is one of my two portfolio sites, and an update to the one i
                built in 2018. Each section is it's own entity, coded to be
                reuasble, flexible and simple. After all, my work motto is
              </p>
              <blockquote class="blockquote">
                <p class="fst-italic">Keep it simple, make it better...</p>
              </blockquote>
              <p class="text-start">
                The idea behind this site was to make a very minimalistic UI,
                that is fully functional, beautiful and stirs away from the
                thousands of monotone websites that all look the same. This
                unique and creative style is what I seek to bring over the web.
                After years of honing my designer skills, observing trends and
                various styles, I gained the ability to work with almost any
                style and still be able to create a unique User experience. The
                various projects I have worked on clearly prove this.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul
        class="nav nav-pills mb-3 nav-fill justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            <span class=""> About Me </span>
            <div class="mt-1"></div>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            <span class=""> More </span>
            <div class="mt-1"></div>
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import AboutButton from "../components/AboutButton.vue";

export default {
  name: "About",
  components: {
    AboutButton
  },
};
</script>

<style scoped>
#aboutme {
  min-height: 100vh;
}
.avatar {
  width: 200px;
  border-radius: 50%;
}
.nav-pills .nav-link {
  background: none;
  transition: all 0.3s ease;
  width: 100%;
  border-radius: 0;
  padding: 2em;
}
.nav-link div {
  background: white;
  opacity: 0.2;
  height: 3px;
}
.nav-pills .nav-link.active {
  opacity: 0.9;
  background: none;
}

.nav-pills .nav-link.active div {
  opacity: 0.9;
  background: white;
}
</style>
