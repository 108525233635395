<template>
  <div class="container-fluid">
    <div class="row">
      <SideBar />
      <HomePage />
      <About />
      <MyProjects />
      <CPage />
      <Footer />
      <ScrollTop />
    </div>
  </div>
</template>

<script>
import HomePage from "./pages/HomePage.vue";
import CPage from "./pages/CPage.vue";
import MyProjects from "./pages/MyProjects.vue";
import About from "./pages/About.vue";
import Footer from "./pages/Footer.vue";
import SideBar from "./components/SideBar.vue";
import ScrollTop from "./components/ScrollTop.vue";

export default {
  name: "App",
  components: {
    HomePage,
    SideBar,
    CPage,
    ScrollTop,
    MyProjects,
    About,
    Footer
  },
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  position: relative;
}


</style>
