<template>
  <div class="col-lg-6 col-sm-12 col-xs-12 mb-4 noselect">
    <div class="card">
      <img :src="'assets/' + img" class="card-img-top m-auto" :alt="title" />
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <p class="card-text">
          {{ text }}
        </p>
        <a :href="link" class="btn btn-primary" target="_blank">
          <i :class="icon"></i> Visit Project</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String
    },
    img: {
      type: String
    },
    text: {
      type: String
    },
    link: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>

<style scoped>
.card {
  background: transparent;
  border: none;
  min-height: 30em;
}
body:hover .card:not(:hover) {
  opacity: 0.5;
}
.card img {
  border-radius: 0;
  max-height: 500px;
  max-width: 500px;
}
.card-title {
  font-weight: bolder;
}
.btn {
  background: #8e24aa;
  border: none;
  border-radius: 0;
  opacity: 0.7;
}
.btn:hover {
  background: #8e24aa;
  opacity: 1;
  transition: all 0.3s ease;
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}
</style>
