<template>
  <section id="projects" class="noselect mb-4">
    <h2 class="text-center fw-bold p-2">Projects</h2>

    <div class="container">
      <p>
        Below are the most remarkable projects i've worked on. Ranging from
        Professional grade management software to beautiful creations.
      </p>
      <div class="row">
        <Card v-for="crd in cards" :key="crd.title" v-bind="crd" />
      </div>
    </div>
    <div class="container">
      <h4 class="text-center fw-bold">Graphic Design Catalog</h4>
      <p>Catalog of a few of the Designs and Creations I've worked on</p>
      <div id="carousel" class="carousel slide" data-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item active noselect">
            <img src="assets/carousel/1.webp" class="col-10 col-xl-6 m-auto" alt="" />
          </div>

          <div v-for="slide in 13" :key="slide" class="carousel-item noselect">
            <img :src="'assets/carousel/' + [slide + 1] + '.webp'" class="d-block m-auto col-10 col-xl-6"
              alt="saint creators" />
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Card from "../components/Card.vue";

const crds = [
  {
    title: "King Georges",
    img: "kg.png",
    text: "King Georges, a reknown printing press entrusted me with the conception and creation of their platform, which was an absolute success.",
    link: "https://kingeorges.com",
    icon: "fas fa-crown",
  },
  {
    title: "Sainte Mathilde",
    img: "smat.png",
    text:
      "Tailored website for a clinic based and operating in Yaounde, Cameroon. " +
      "The platform provides an extensive set of tools for not only communication but also customer attraction and attention. Backed by Handler, a custom CMS used to manage patients, inventory, etc, made by me aswell.",
    link: "https://saintemathilde.com",
    icon: "fas fa-stethoscope",
  },
  {
    title: "VSP TECH",
    img: "vsp.png",
    text:
      "VSP TECH is a startup based in Yaounde, Cameroun. Our aim was to change the view of technology " +
      "and make it accessible throught Central Africa and abroad. I left the team in 2019 and moved in search of newer prospects",
    link: "https://vsptech.cm",
    icon: "fas fa-microchip",
  },
  {
    title: "LuftundKlima24",
    img: "luk.png",
    text:
      "The Krefeld based well known HVAC company Luftundklima was in dire need of penetrating the e-commerce space. " +
      "Working as the Lead developer and designer, I came up with a new intuitive, cleaner, faster and more convenient platform which played a major role in user retention and increase in traffic ",
    link: "https://www.luftundklima24.de",
    icon: "fas fa-fan",
  },
  {
    title: "Perspectives Climate Group",
    img: "perspectives.png",
    text:
      "Perspectives Climate Group: As Head of IT at Perspectives Climate Group, I led the development of the organization's website and managed its network infrastructure. Perspectives Climate Group " +
      " is dedicated to accelerating climate action by engaging businesses, governments, and communities to promote sustainable practices and reduce carbon emissions. I am proud to have contributed to this mission and worked with such a passionate team. ",
    link: "https://perspectives.cc",
    icon: "fa fa-globe",
  },
  {
    title: "ICON Foundation",
    img: "icon.png",
    text:
      "Our latest project is in collaboration with ICON, working towards empowering communities through education and sustainable development. Through this partnership, we aim to bring awareness to mental health issues faced particularly by foreign students in Germany.  " +
      " and create positive change in the community. Visit our website to learn more about this meaningful project and how you can get involved.",
    link: "https://icon-foundation.de",
    icon: "fa fa-solid fa-hand-holding-heart",
  },
];

export default defineComponent({
  name: "MyProjects",
  components: {
    Card,
  },
  setup() {
    return {
      cards: crds,
    };
  },
});
</script>

<style>
.carousel-inner:active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
</style>
