<template>
  <div>
    <a
      type="button"
      class="btn btn-primary mx-4 my-1 d-flex"
      :class="btn"
      :href="link"
      target="_blank"
      :title="title"
    >
      <i :class="icon" class="m-auto"></i>
    </a>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "ContactButton",
  props: {
    title: {
      type: String,
    },
    link: {
      type: String,
    },
    icon: {
      type: String,
    },
    btn: {
      type: String,
    },
    target: {
      type: String,
    },
  },
};
</script>

<style scoped>
a {
  cursor: none !important;
}
.btn {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  overflow: hidden;
  border: none;
  box-shadow: none;
  cursor: none;
  transition: all 0.3s ease;
  width: 4.5em;
  height: 4.5em;
}

.btn:hover {
  -webkit-transform: rotateZ(-15deg);
  -ms-transform: rotateZ(-15deg);
  transform: rotateZ(-15deg);
}

i {
  font-size: 2em;
}

.resume {
  background: #4b515d;
}
.resume:hover{
  background: #2a2d33;
}
.linkedin {
  background: #0e76a8;
}
.linkedin:hover {
  background: #0b5c81;
}
.git {
  background: #333;
}
.git:hover {
  background: rgb(10, 10, 10);
}
</style>
