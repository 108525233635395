<template>
  <section id="contact" class="mt-2 noselect">
    <h2 class="text-center fw-bold p-2">Contact me</h2>
    <div class="heading align-items-center">
      <p class="my-3 fs-5">
        You can contact me via the following platforms. Just click to get
        started
      </p>
      <div
        class="col d-flex flex-wrap align-content-center justify-content-center"
      >
        <ContactButton v-for="btn in buttons" :key="btn.title" v-bind="btn" />
      </div>
    </div>
    <div class="container py-4">
      <!-- Bootstrap 5 starter form -->
      <p class="my-4 fs-5">Or directly via this simple form.</p>
      <form
        id="contactForm"
        name="contactForm"
        class="text-start col-lg-8 mx-auto"
        netlify
      >
        <!-- Name input -->
        <div class="mb-3">
          <label class="form-label" for="name">Name</label>
          <input class="form-control" id="name" type="text" name="Name" required/>
        </div>

        <!-- Email address input -->
        <div class="mb-3">
          <label class="form-label" for="emailAddress">Email Address</label>
          <input
            class="form-control"
            id="emailAddress"
            type="email"
            name="email"
            required
          />
        </div>

        <!-- Message input -->
        <div class="mb-3">
          <label class="form-label" for="message">Message</label>
          <textarea
            class="form-control"
            id="message"
            type="text"
            rows="10"
            name="message"
            required
          ></textarea>
        </div>

        <!-- Form submit button -->
        <div class="d-grid">
          <button class="btn btn-primary btn-lg mx-auto" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import ContactButton from "../components/ContactButton.vue";

const btns = [
  {
    title: "Call (+49 152-556-1913)",
    icon: "fas fa-phone",
    link: "tel:015255619137",
    target: "_blank",
    btn: "wa",
  },
  {
    title: "Mail",
    icon: "fas fa-envelope",
    link: "mailto:stevenfokoua@saintcreators.com",
  },
  {
    title: "Whatsapp",
    icon: "fab fa-whatsapp",
    link: "https://api.whatsapp.com/send?phone=4915255619137&text=Hello I got your number from your portfolio",
    btn: "wa",
  },

  {
    title: "Google Forms",
    icon: "fab fa-google",
    link: "https://forms.gle/dNyqdJnRP7qGafVt6",
    btn: "google",
    target: "_blank",
  },
  {
    title: "Upwork",
    icon: "fas fa-arrow-up",
    link: "https://www.upwork.com/freelancers/~0173ba845394f7870b",
    btn: "upwork",
    target: "_blank",
  },
];

export default {
  name: "CPage",
  components: {
    ContactButton,
  },
  setup() {
    return {
      buttons: btns,
    };
  },
};
</script>
<style scoped>
section {
  min-height: 100vh;
  color: white;
  transition: all 0.3s ease;
}
input,
textarea {
  background: rgba(0, 0, 0, 0.1);
  border: none;
  border-bottom: 2px black;
  transition: all 0.3s ease;
  color: white;
}
input:focus,
textarea:focus {
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}
.btn {
  background: #8e24aa;
  border: solid #8e24aa;
  width: 40%;
  transition: all 0.3s ease;
  opacity: 0.8;
}
.btn:hover {
  width: 100%;
  opacity: 1;
}
</style>
