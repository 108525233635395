<template>
  <footer style="background-color: rgba(0, 0, 0, 0.05)">
    <!-- Copyright -->
    <div class="text-center p-3" >
      Made with Vue and love by
      <a class="text-reset fw-bold" href="#"
        >Saintcreators</a
      >
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style></style>
