<template>
  <div>
    <a id="scrolltop" class="btn rotate d-none d-md-block" href="#home">
      <i class="fas fa-long-arrow-alt-up"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "ScrollTop",
  mounted() {
  },
};
</script>

<style scoped>
#scrolltop {
  position: fixed;
  right: 4rem;
  bottom: 3rem;
  color: white;
  border: none;
  font-size: 2em;
  font-weight: bolder;
  background: none;
  transition: all 0.3s ease;
}
.rotate:hover {
  transform: translateY(-10px);
}

</style>
