<template>
  <div>
    <a
      type="button"
      class="btn btn-primary mx-4 my-1"
      :class="btn"
      :href="link"
      :target="target"
      :title="title"
    >
      <i :class="icon"></i>
    </a>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "ContactButton",
  props: {
    title: {
      type: String,
    },
    link: {
      type: String,
    },
    icon: {
      type: String,
    },
    btn: {
      type: String,
    },
    target: {
      type: String,
    },
  },
};
</script>

<style scoped>
a {
  cursor: none !important;
}
.btn i {
  font-size: 3em;
  cursor: none;
}
.btn {
  padding: 1.5em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  overflow: hidden;
  border: none;
  box-shadow: none;
  cursor: none;
  transition: all 0.3s ease;
  box-shadow: 0 5px 1px 0 rgb(0 0 0 / 5%), 0 4px 5px 0 rgb(0 0 0 / 5%);
}
.btn:hover {
  -webkit-transform: rotateZ(-30deg);
  -ms-transform: rotateZ(-30deg);
  transform: rotateZ(-30deg);
}
.wa {
  background: #25d366;
}
.wa:hover {
  background: #199c49;
}
.upwork {
  background: #14a800;
}
.upwork:hover {
  background: #0f7e00;
}

.google {
  background: #ea4335;
}
.google:hover {
  background: #d13c2f;
}
.grey{
  background: #4b515d;
}
.sm {
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
.sm i {
  font-size: 1rem;
}
</style>
