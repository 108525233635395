<template>
  <nav id="sidebar" class="navbar noselect fixed-top ms-lg-2">
    <ul  class="navbar-nav p-2 gap-4">
      <li class="nav-item ">
        <a href="#home" class="nav-link" title="Home"
          ><i class="fas fa-home"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#aboutme" class="nav-link" title="About me"
          ><i class="fas fa-graduation-cap"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#projects" class="nav-link" title="Projects"
          ><i class="fas fa-code"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#contact" class="nav-link" title="Contact"
          ><i class="fas fa-envelope"></i
        ></a>
      </li>
    </ul>
  </nav>
  <nav id="sidebar-mobile" class="navbar nav-bottom noselect d-sm-none fixed-bottom ">
    <ul class="navbar-nav justify-content-around flex-row">
      <li class="nav-item ">
        <a href="#home" class="nav-link" title="Home"
          ><i class="fas fa-home"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#aboutme" class="nav-link" title="About me"
          ><i class="fas fa-graduation-cap"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#projects" class="nav-link" title="Projects"
          ><i class="fas fa-code"></i
        ></a>
      </li>
      <li class="nav-item">
        <a href="#contact" class="nav-link" title="Contact"
          ><i class="fas fa-envelope"></i
        ></a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'SideBar'
}
</script>

<style scoped>
#sidebar {
  height: 100vh;
  width: fit-content;
}
#sidebar-mobile{
  display: block;
}
.nav-bottom{
  background: rgba(1,1,1,0.1);
}
a,
a:hover,
.nav-link:focus {
  color: white;
}
.nav-item {
  opacity: 0.7;
}
.nav-item:hover,
.nav-item:active, .nav-active {
  opacity: 1;
  color: white;
  -webkit-transform: translateX(-3px);
  transform: scale(1.05);
}
i {
  font-size: 1.4em;
}

</style>
